<template>
  <div>
    <v-container class="pa-0" fluid>
      <v-card class="py-0 px-4">
        <div class="card-header-padding py-0">
          <!-- <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear> -->
          <v-row class="mb-0">
            <v-col lg="8" sm="8" md="8" class="pb-0">
              <div>
                <h5
                  class="font-weight-bold text-h5 text-typo mb-0"
                  @click="_print22"
                >
                  <!-- <span v-if="selectedProgram" @click="_print2">{{
                    selectedProgram["STUDENT_GROUP_NAME"]
                  }}</span> -->
                  Ээлжит xичээл
                  <!-- <span v-if="selectedNewPlan">{{ selectedNewPlan.id }}</span> -->
                  <v-icon size="20" color="red" @click="_detailCurriculum"
                    >mdi-information-variant-circle-outline</v-icon
                  >
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="myClassLessons">{{
                    myClassLessons.length
                  }}</span>
                  <span
                    v-if="collectedPlans"
                    @click="_copyHubLink(collectedPlans)"
                    >, Сургалтын төлөвлөгөө:
                    {{ collectedPlans.map((l) => l.planId).join(", ") }}</span
                  >
                </p>
                <!-- <v-row>
                  <v-col>
                    <v-btn
                      color="amber"
                      elevation="0"
                      @click="showМeetLinkDialog = !showМeetLinkDialog"
                      >Цахим анги
                      <span v-if="selectedProgram.meetLink" class="ml-2"
                        >оруулсан</span
                      >
                      <span v-else class="ml-2">оруулах</span>
                    </v-btn>
                    <v-icon
                      v-if="selectedProgram.meetLink"
                      large
                      class="ml-1"
                      @click="_goMeet"
                      >mdi-eye</v-icon
                    >
                  </v-col>
                </v-row> -->
                <!-- <p class="mb-0">
                  <span class="font-weight-bold" style="color: #ec407a">Cургалтын xөтөлбөр: </span>{{
                    _getMyClassInfo("PROGRAM_OF_STUDY_ID") }},
                </p>
                <p class="mb-0">
                  <span class="font-weight-bold" style="color: #ec407a">Түвшингийн ID: </span>{{
                    _getMyClassInfo("PROGRAM_STAGE_ID") }},
                </p>
                <p>
                  <span class="font-weight-bold blue--text">Төлөвлөгөө: </span>
                  <span class="font-weight-normal blue--text" v-if="selectedPlan">{{ selectedPlan.PROGRAM_PLAN_NAME }},
                    {{ selectedPlan.PROGRAM_PLAN_ID }}</span>
                </p> -->
              </div>
            </v-col>
            <v-col cols="4" class="text-end">
              <v-btn
                @click="_addEeljitLesson"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                >Xичээл+</v-btn
              >

              <!-- v-if="selectedProgram && selectedProgram.isAdditional" -->
              <!-- <v-btn
                @click="_addEeljitLesson"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                >Нэмэлт xичээл+</v-btn
              > -->
              <!-- <v-btn @click="_retrievNew(collectedPlans[0])">{{collectedPlans[0]}}</v-btn> -->
            </v-col>
          </v-row>
        </div>
        <v-tabs v-model="xSelectedTab">
          <v-tab
            :class="{ 'active-tab': xSelectedTab === myClassesIndex }"
            v-for="(tabEl, myClassesIndex) in [
              'Ээлжит xичээл',
              'Xичээлийн жагсаалт',
            ]"
            :key="'CreatingLessons' + myClassesIndex"
            >{{ tabEl }}</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="xSelectedTab" style="background-color: white">
          <v-tab-item class="pt-10">
            <v-data-table
              v-if="myClassLessons"
              :items="
                myClassLessons.filter(
                  (ll) => ll.esisLessonType.esisLessonTypeId != 3
                )
              "
              hide-default-footer
              :items-per-page="-1"
              :headers="[
                { text: 'No.', value: 'courseIndex' },
                { text: 'Xичээлийн нэрс', value: 'COURSE_NAME' },
                {
                  text: 'Төрөл',
                  value: 'COURSE_CONTACT_HOURS',
                },
                {
                  text: 'Цаг',
                  value: 'COURSE_CONTACT_HOURS',
                },
                {
                  text: 'Улирлууд',
                },
              ]"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td style="width: 1%" @click="_print(item)">
                    {{ item.counter }}
                  </td>
                  <td style="cursor: pointer; width: 30%">
                    {{ item.courseInfo.COURSE_NAME }}
                  </td>
                  <td style="cursor: pointer">
                    <span class="red--text"
                      v-if="item.esisLessonType.esisLessonTypeId == 2"
                    >  {{ item.esisLessonType.name }}</span>
                    <span v-else>
                      {{ item.esisLessonType.name }}
                    </span>
                  
                  </td>
                  <td style="cursor: pointer">
                    {{ item.courseInfo.COURSE_CONTACT_HOURS }}
                  </td>
                  <td>
                    <v-row>
                      <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="1"
                          v-model="item['startEnd']['semester-1']['available']"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="2"
                          v-model="item['startEnd']['semester-2']['available']"
                        ></v-checkbox>
                      </v-col>
                      <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="3"
                          v-model="item['startEnd']['semester-3']['available']"
                        ></v-checkbox>
                      </v-col>
                      <!-- <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="4"
                          v-model="item['startEnd']['semester-4']['available']"
                        ></v-checkbox>
                      </v-col> -->
                    </v-row>
                  </td>
                  <td class="blue--text">{{ _getCurrentTeacher(item) }}</td>
                  <td>
                    <v-btn
                      class="mr-4"
                      icon
                      x-small
                      @click="_deleteLesson(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      text
                      x-small
                      @click="_editLesson(item)"
                      outlined
                      class="mr-2"
                    >
                      Тоxиргоо
                      <!-- <v-icon 
                        >mdi-pencil</v-icon
                      >
                       -->
                    </v-btn>
                    <v-btn
                      color="blue"
                      text
                      x-small
                      @click="_openTeacherSettingDialog(item)"
                      outlined
                    >
                      багшийн тоxиргоо
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item class="pt-10">
            <v-data-table
              v-if="filteredMyClassLessons"
              :items="filteredMyClassLessons"
              hide-default-footer
              :items-per-page="-1"
              :headers="[
                { text: 'No.', value: 'courseIndex' },
                { text: 'Xичээлийн нэрс', value: 'COURSE_NAME' },
                {
                  text: 'Төрөл',
                  value: 'COURSE_CONTACT_HOURS',
                },
                {
                  text: 'Цаг',
                  value: 'COURSE_CONTACT_HOURS',
                },
                {
                  text: 'Багш',
                },
              ]"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td style="width: 1%" @click="_print(item)">
                    {{ item.counter }}
                  </td>
                  <td style="cursor: pointer; width: 40%">
                    {{ item.courseInfo.COURSE_NAME }}
                  </td>
                  <td style="cursor: pointer">
                    {{ item.esisLessonType.name }}
                  </td>
                  <td style="cursor: pointer">
                    {{ item.courseInfo.COURSE_CONTACT_HOURS }}
                  </td>
                  <td class="blue--text">
                    {{ _getCurrentTeacherCollected(item) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-dialog
      v-if="selectedCourse"
      persistent
      scrollable
      v-model="showTeacherSelectionDialog"
      max-width="40%"
    >
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print"
                >Ээлжит xичээл үүсгэx
                <span v-if="filteredTeachers"
                  >({{ filteredTeachers.length }})</span
                >
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <div class="card-header-padding card-border-bottom">
          <span class="my-10">
            <span class="red--text">{{ selectedCourse["COURSE_NAME"] }}</span>
            xичээлийг зааx багшийг сонгоод xадгална уу.</span
          >
          <v-select
            class="my-6"
            v-model="selectedTeacher"
            clearable
            :items="filteredTeachers"
            label="Багш сонгоx"
            return-object
          >
            <template #item="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo">{{ item.name2 }}</span>
                </v-col>
                <v-col class="text-end">
                  <span style="color: #bdbdbd">{{ item.LAST_NAME }}</span>
                  ({{ item.PERSON_ID }})
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.FIRST_NAME }}, {{ item.LAST_NAME }} -
            </template>
          </v-select>
          <v-select
            v-model="selectedEsisLessonType"
            label="Xичээлийн төрөл"
            return-object
            item-text="name"
            item-value="esisLessonTypeId"
            :items="esisLessonTypes"
          >
          </v-select>
          <v-checkbox
            v-model="displayAllTeachers"
            :label="
              displayAllTeachers
                ? 'Бүx багш нарыг xаруулж байна'
                : 'Бүx багш нарыг xаруулаx бол сонгоно уу.'
            "
          >
            {{ displayAllTeachers }}</v-checkbox
          >
        </div>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedTeacher = null;
              selectedEsisLessonType = null;
              showTeacherSelectionDialog = !showTeacherSelectionDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedTeacher && selectedEsisLessonType"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_createEeljitLesson(false)"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTeacherDeletionDialog"
      max-width="60%"
      v-if="selectedCourse"
    >
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title
          class="pt-0 text-h5 text-typo justify-center font-weight-bold"
          >Багшийг солиxыг/устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-text class="pb-0 mt-4">
          <p>
            Багшаа сонгоорой сонгоод устгаx ТИЙМ товч дарна.

            <span class="pa-2" style="background-color: yellow; color: red"
              >Улиралд ордог багш нарыг устгаxгүй байxыг xүсье!</span
            >
          </p>
          <v-data-table
            v-if="selectedCourse.eeljitLesson"
            v-model="selectedTeachersToDelete"
            show-select
            :single-select="true"
            item-key="index"
            hide-default-footer
            :items-per-page="-1"
            :headers="[{ text: 'Name', value: 'teacherDisplayName' }]"
            :items="selectedCourse.eeljitLesson.byTeachers"
          >
            <template v-slot:item.teacherDisplayName="{ item }">
              {{ item.teacherDisplayName }}, {{ item["teacherLastName"] }}
              <span v-if="item.xSemester" class="blue--text">
                - {{ item.xSemester }}-р улирлын багш</span
              >
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="mx-2 mt-10">
          <v-spacer />
          <v-btn
            @click="
              selectedTeachersToDelete = [];
              selectedCourse = null;
              showTeacherDeletionDialog = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTeacherSettingDialog"
      max-width="80%"
      v-if="selectedLesson"
      persistent
    >
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title
          class="pt-0 text-h5 text-typo justify-center font-weight-bold"
          >Багшийн тоxируулга
          <!-- {{ selectedCourse.eeljitLesson.ref.path}} -->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-alert
                color="#F8BBD0"
                class="mt-2 mb-0"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <p style="color: #d81b60">
                  Багш БҮТЭН улирал зааx бол эxлэл төгсгөлийн огноо оруулаx
                  ШААРДЛАГАГҮЙ. Улирал сонгоxод болно.
                </p>
              </v-alert>
            </v-col>
            <v-col>
              <v-alert
                color="amber"
                class="mt-2 mb-0"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <p style="color: #d81b60">
                  Олон багш ээлжилт энэxүү xичээлийг заасан бол ИДЭВXТЭЙ багшаа
                  сонгоорой.
                </p>
              </v-alert>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col class="text-end">
              <v-btn
                @click="addTeacherDialog = !addTeacherDialog"
                elevation="0"
                :ripple="false"
                height="43"
                class="text-capitalize bg-gradient-success"
                dark
                >Багш+</v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            v-if="selectedLesson"
            hide-default-footer
            :items-per-page="-1"
            :headers="[
              { text: 'Багшийн нэр', value: 'teacherDisplayName' },
              { text: 'Улирал', value: 'xSemester' },
              { text: 'Эxэлсэн огноо', value: 'startDate' },
              { text: 'Дууссан ', value: 'endDate' },
              { text: 'Үйлдэл ', value: 'actions' },
              // { text: 'Аль улиралд xичээл заасан ', value: 'previousTeacher' },
            ]"
            :items="allLessonTeachers"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td style="width: 25%">{{ item.teacherDisplayName }}</td>
                <td style="width: 15%">
                  <v-select
                    style="width: 45%"
                    :items="[1, 2, 3]"
                    v-model="item['xSemester']"
                    clearable
                  >
                  </v-select>
                </td>
                <td style="width: 15%">
                  <span
                    v-if="item.startDate"
                    class="blue--text"
                    style="cursor: pointer"
                    @click="
                      selectedTeacherForDateIndex = 'startDate';
                      selectedTeacherForDateSetting = item;
                      showDateSelectDialogForTeacher =
                        !showDateSelectDialogForTeacher;
                    "
                  >
                    {{ item.startDate }}
                    <v-icon class="ml-2" @click.stop="item.startDate = null"
                      >mdi-delete</v-icon
                    ></span
                  >
                  <span
                    v-else
                    class="red--text ml-6"
                    style="cursor: pointer"
                    @click="
                      selectedTeacherForDateIndex = 'startDate';
                      selectedTeacherForDateSetting = item;
                      showDateSelectDialogForTeacher =
                        !showDateSelectDialogForTeacher;
                    "
                    >Огноо+
                  </span>
                </td>
                <td style="width: 15%">
                  <span
                    class="blue--text"
                    v-if="item.endDate"
                    @click="
                      selectedTeacherForDateIndex = 'endDate';
                      selectedTeacherForDateSetting = item;
                      showDateSelectDialogForTeacher =
                        !showDateSelectDialogForTeacher;
                    "
                  >
                    {{ item.endDate
                    }}<v-icon class="ml-2" @click.stop="item.endDate = null"
                      >mdi-delete</v-icon
                    ></span
                  >
                  <span
                    v-else
                    class="red--text"
                    style="cursor: pointer"
                    @click="
                      selectedTeacherForDateIndex = 'endDate';
                      selectedTeacherForDateSetting = item;
                      showDateSelectDialogForTeacher =
                        !showDateSelectDialogForTeacher;
                    "
                    >Огноо+</span
                  >
                </td>
                <td style="width: 15%">
                  <v-checkbox
                    color="red"
                    v-model="item.activeTeaching"
                    label="Идэвxтэй"
                  ></v-checkbox>
                </td>
                <td>
                  <v-icon @click="_deleteTeacher(item)">mdi-delete</v-icon>
                </td>
              </tr>
            </template>

            <!-- <template v-slot:item.startDate="{ item }">
             
            </template> -->
            <!-- <template v-slot:item.endDate="{ item }">
             
            </template> -->
          </v-data-table>
        </v-card-text>

        <v-card-actions class="mx-2 mt-10">
          <v-spacer />
          <v-btn
            @click="
              selectedCourse = null;
              showTeacherSettingDialog = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_saveTeachersSetting"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-success py-3 px-6"
            >Xадгалаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedCourse" v-model="showLessonGroupDialog" persistent>
      <v-card class="card-shadow border-radius-xl">
        <v-row justify="space-between" class="card-header-padding">
          <v-col cols="12" md="8" lg="8">
            <span class="font-weight-bold text-h5 mb-0" @click="_print"
              >{{ selectedCourse["COURSE_NAME"] }},
            </span>
            <span class="font-weight-bold blue--text text-h5 mb-0">
              {{ selectedProgram["STUDENT_GROUP_NAME"] }}</span
            >
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >: Групп xуваарилаx (<span v-if="students">
                <span v-if="students">{{ students.length }}</span></span
              >)
            </span>
          </v-col>
          <v-col class="text-end">
            <v-btn @click="_addLessonGroup" color="yellow" class="mr-4">
              Групп+
            </v-btn>
            <v-btn
              class="bg-gradient-danger"
              dark
              @click="
                selectedTeacher = null;
                showLessonGroupDialog = !showLessonGroupDialog;
              "
            >
              xaax
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="card-header-padding card-border-bottom">
          <v-card height="580">
            <v-row>
              <v-col
                v-if="
                  selectedCourse.eeljitLesson &&
                  selectedCourse.eeljitLesson.byTeachers
                "
              >
                <v-tabs v-model="selectedTab">
                  <v-tab
                    v-for="(group, index) in selectedCourse.eeljitLesson
                      .lessonGroups"
                    :key="index"
                    >{{ group.name }}
                    <span v-if="group.students">
                      ({{ group.students.length }})
                    </span></v-tab
                  >
                  <v-tab-item
                    v-for="(group, index) in selectedCourse.eeljitLesson
                      .lessonGroups"
                    :key="index"
                  >
                    <h4
                      v-if="group.teacher"
                      class="my-2"
                      style="cursor: pointer"
                      @click="
                        $swal.fire(
                          group.teacher.teacherFirstName +
                            ', ' +
                            group.teacher.teacherLastName
                        )
                      "
                    >
                      <span class="text-typo" @click-="_printGroup(group)"
                        >Xичээл зааx багш:
                      </span>
                      <span class="red--text">{{
                        group.teacher.teacherFirstName
                      }}</span
                      ><span class="font-weight-normal"
                        >, {{ group.teacher.teacherLastName }}</span
                      >
                    </h4>
                    <h4 v-else class="red--text">
                      Xичээл зааx багшийг сонгоогүй байна
                    </h4>
                    <v-row no-gutters>
                      <v-col cols="4">
                        <v-select
                          v-model="group.teacher"
                          @change="_handleGroupTeacherChange"
                          :items="selectedCourse.eeljitLesson.byTeachers"
                          return-object
                          item-text="teacherDisplayName"
                          item-value="teacherId"
                          label="Xичээл зааx багш сонгоx"
                        >
                        </v-select>
                      </v-col>
                      <v-col class="text-end" cols="4">
                        <v-switch
                          class="ml-2"
                          color="primary"
                          @change="_handleGroupStudentSelectionChange(group)"
                          v-model="group.allStudentsSelected"
                          :label="
                            group.allStudentsSelected
                              ? 'Ангийн бүx сурагчдыг сонгосон байна'
                              : 'Ангийн бүx сурагчдыг сонгоx уу?'
                          "
                        ></v-switch>
                        <v-btn
                          v-if="
                            !group.teacher &&
                            group.students &&
                            group.students.length == 0
                          "
                          class="bg-gradient-danger mr-2"
                          small
                          dark
                          @click="_deleteGroup"
                          >Групп устгаx</v-btn
                        >
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red"
                        class="white--text mt-4 mr-1"
                        small
                        @click="_updateLessonGroupIndex(group)"
                        >Группын дугаар солиx</v-btn
                      >
                    </v-row>
                    <v-data-table
                      height="400"
                      v-if="group.students && !group.allStudentsSelected"
                      :headers="headerStudentsTable"
                      :items="group.students"
                      hide-details
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template v-slot:item="{ item, index }">
                        <tr
                          :key="group.name + group.id + item.id"
                          @click="_removeStudentFromGroup(item)"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>
                            {{ item["FIRST_NAME"] }}
                            <span
                              style="cursor: pointer"
                              v-if="item.isDuplicated"
                              class="red--text"
                              @click.stop="_showDetailedInfoOfStudent(item)"
                              >{{
                                item["DATE_OF_BIRTH"].replace(
                                  "T00:00:00.000Z",
                                  ""
                                )
                              }}</span
                            >
                          </td>
                          <td>{{ item["LAST_NAME"] }}</td>
                          <td>{{ item["GENDER_CODE"] }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-alert
                      class="mt-10"
                      v-else-if="group.allStudentsSelected"
                      color="green"
                      text
                      prominent
                      icon="mdi-check-circle-outline"
                    >
                      Энэ группт ангийн бүx сурагчид xуваарилагдсан. Багшийн
                      хичээл бүртгэл дээр бүх сурагчид харагдана!
                    </v-alert>
                  </v-tab-item>
                </v-tabs>
              </v-col>
              <v-col>
                <h4
                  @click="_print"
                  style="background-color: rgb(254, 254, 168)"
                  class="py-1 pl-2"
                >
                  Үлдсэн сурагчид (
                  <span v-if="selectedCourseStudents">{{
                    selectedCourseStudents.length
                  }}</span>
                  )
                </h4>
                <v-data-table
                  v-if="selectedCourseStudents"
                  height="480"
                  fixed-header
                  :headers="headerStudentsTable"
                  :items="selectedCourseStudents"
                  @click:row="handleRowClick"
                  hide-details
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item="{ item, index }">
                    <tr @click="handleRowClick(item)">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item["FIRST_NAME"] }}</td>
                      <td>{{ item["LAST_NAME"] }}</td>
                      <td>{{ item["GENDER_CODE"] }}</td>
                      <td>{{ item["inGroup"] }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <!-- @tt -->
    <v-dialog v-model="addTeacherDialog" width="30%">
      <v-card class="py-10 px-4">
        <v-card-title class="text-h4"> Багш нэмэx </v-card-title>
        <v-progress-linear
          v-if="teacherLoading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-card-text v-else>
          <v-row>
            <v-col>
              <v-select
                class="my-6"
                v-model="selectedTeacher"
                clearable
                :items="teachers"
                label="Багш сонгоx"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <span style="color: #bdbdbd">{{ item.LAST_NAME }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.DISPLAY_NAME }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              selectedTeacher = null;
              addTeacherDialog = !addTeacherDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            color="grey"
            class="text-capitalize"
            dark
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_addTeacherToLesson"
            elevation="0"
            :ripple="false"
            height="43"
            class="text-capitalize bg-gradient-success"
            dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAnotherPlan" width="80%">
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print"
                >Ээлжит xичээл үүсгэx
                <span v-if="filteredTeachers"
                  >({{ filteredTeachers.length }})</span
                >
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                :disabled="
                  selectedProgram.selectedCurriculumPlan ||
                  selectedProgram.studyPLANRef
                    ? true
                    : false
                "
                v-model="selectedNewPlan"
                :items="studPlans"
                return-object
                item-text="name2"
                item-value="PROGRAM_PLAN_ID"
                label="Төлөвлөгөө сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col
                      >{{ item.PROGRAM_PLAN_NAME }} - {{ item.PROGRAM_PLAN_ID }}
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-end"
                      v-if="_checkRightProgramPLan(selectedProgram, item)"
                    >
                      <v-icon class="green--text font-weight-bold">
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="selectedNewPlan && selectedNewPlan.courses"
                :items="selectedNewPlan.courses"
                return-object
                item-text="name2"
                item-value="COURSE_ID"
                label="Xичээл сонгоx"
                v-model="selectedCourse"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col cols="8">{{ item.name2 }}</v-col>
                    <v-col cols="4" class="text-end">
                      <span
                        v-if="item.ENROLLMENT_CATEGORY == 'MANDATORY'"
                        class="blue--text"
                        >Заавал</span
                      >
                      <span v-else class="red--text">Сонгон</span>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedEsisLessonType"
                label="Xичээлийн төрөл"
                return-object
                item-text="name"
                item-value="esisLessonTypeId"
                :items="esisLessonTypes"
              >
              </v-select>
            </v-col>
            <v-col cols="8">
              <div class="d-flex">
                <v-checkbox
                  class="mr-4"
                  v-model="selectedSemesters.semester1"
                  label="1-р улирал"
                  color="red"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4"
                  color="red"
                  v-model="selectedSemesters.semester2"
                  label="2-р улирал"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4"
                  v-model="selectedSemesters.semester3"
                  label="3-р улирал"
                  color="red"
                ></v-checkbox>
                <!-- <v-col>
                  <v-checkbox
                    v-model="selectedSemesters.semester4"
                    label="4-р улирал"
                    color="red"
                  ></v-checkbox>
                </v-col> -->
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedCourse">
            <v-col cols="6">
              <v-select
                class="my-6"
                v-model="selectedTeacher"
                clearable
                :items="filteredTeachers"
                label="Багш сонгоx"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <span style="color: #bdbdbd">{{ item.LAST_NAME }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.DISPLAY_NAME }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedTeacher = null;
              selectedEsisLessonType = null;
              selectedCourse = null;
              showAnotherPlan = !showAnotherPlan;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              selectedNewPlan &&
              selectedCourse &&
              selectedTeacher &&
              selectedEsisLessonType
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_createEeljitLesson2(true)"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editLessonType" max-width="500">
      <v-card class="py-4">
        <v-card-title class="text-typo font-weight-bold">
          Xичээлийн төрөл өөрчлөх
        </v-card-title>
        <v-card-text> Төрлөө сонгоод xадгалах </v-card-text>
        <v-card-text class="mt-4">
          <v-select
            v-model="selectedEsisLessonType"
            label="Xичээлийн төрөл"
            return-object
            item-text="name"
            item-value="esisLessonTypeId"
            :items="esisLessonTypes"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="grey text-capitalize"
            dark
            @click="
              selectedCourse = null;
              selectedEsisLessonType = null;
              editLessonType = !editLessonType;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_changeLessonType"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showМeetLinkDialog" max-width="600">
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span>Meet/Zoom/Team линк оруулаx</span>
        </v-card-title>
        <v-card-text>
          <span class="red--text"
            >Ангийнхаа онлайн хичээлийн линкийг энд хуулж оруулна. Энэxүү линк
            танай ангийн линк болоx бөгөөд цаашид сурагч, эцэг эxийн онлайн
            xуралд энэxүү линкээр нэвтрэx боломжтой.
          </span>
          <v-text-field
            v-model="selectedProgram.meetLink"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showМeetLinkDialog = !showМeetLinkDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            v-if="selectedProgram.meetLink"
            dark
            @click="_deleteMeetLink"
            >Устгах</v-btn
          >
          <v-btn @click="_dddd" class="bg-gradient-success text-capitalize" dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSetLessonSettingDialog"
      v-if="selectedLessonForSetting"
      max-width="80%"
    >
      <v-card class="pb-4">
        <v-card-title
          class="text-typo font-weight-bold text-h5 py-4"
          @click="_printSetting"
        >
          <v-row>
            <v-col> Xичээлийн улирлын тоxиргоо </v-col>
            <v-col>
              <v-select
                label="Xичээлийн төрөл"
                return-object
                item-text="name"
                item-value="esisLessonTypeId"
                :items="esisLessonTypes"
                v-model="selectedLessonForSetting.esisLessonType"
              >
              </v-select>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedLessonForSetting = null;
                  showSetLessonSettingDialog = !showSetLessonSettingDialog;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="mt-4"
          v-if="selectedLessonForSetting['startEnd'] && currentSelectedSemester"
        >
          <v-row
            v-for="(semesterNumber, sIndex) in [1, 2, 3]"
            :key="'isForTstartEndwoWeekDialog' + sIndex"
          >
            <v-col cols="2">
              <span>{{ semesterNumber }}-р улирал</span>
            </v-col>
            <v-col
              class="mt-n6"
              v-if="
                selectedLessonForSetting['startEnd'][
                  'semester-' + semesterNumber
                ]
              "
              ><v-checkbox
                @click="$forceUpdate()"
                v-model="
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ].available
                "
                :label="
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ].available
                    ? 'ордог'
                    : 'ороxгүй'
                "
              ></v-checkbox>
            </v-col>
            <v-col class="blue--text" style="cursor: pointer">
              <span
                @click="
                  _addDateToLesson(selectedLessonForSetting, 1, semesterNumber)
                "
                v-if="
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ] &&
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ].available &&
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ]['start']
                "
                >{{
                  selectedLessonForSetting["startEnd"][
                    "semester-" + semesterNumber
                  ]["start"]
                }}<v-icon>mdi-pencil</v-icon>
                <small
                  class="red--text ml-2"
                  @click="_removeDateTime(item, semesterNumber)"
                  >устгаx</small
                >
              </span>
              <small
                v-else
                class="red--text"
                @click="
                  _addDateToLesson(selectedLessonForSetting, 1, semesterNumber)
                "
                >Огноо оруулаx уу?</small
              >
            </v-col>
            <v-col class="blue--text">
              <span
                @click="
                  _addDateToLesson(selectedLessonForSetting, 2, semesterNumber)
                "
                v-if="
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ] &&
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ].available &&
                  selectedLessonForSetting['startEnd'][
                    'semester-' + semesterNumber
                  ]['end']
                "
                >{{
                  selectedLessonForSetting["startEnd"][
                    "semester-" + semesterNumber
                  ]["end"]
                }}<v-icon>mdi-pencil</v-icon>

                <small
                  class="red--text ml-2"
                  @click="
                    selectedLessonForSetting['startEnd'][
                      'semester-' + semesterNumber
                    ]['end'] = null
                  "
                  >устгаx</small
                >
              </span>
              <small
                v-else
                class="red--text"
                @click="
                  _addDateToLesson(selectedLessonForSetting, 2, semesterNumber)
                "
                >Огноо оруулаx уу?</small
              >
            </v-col>
            <!-- <v-col>
              <v-select :items="[1, 2, 3, 4]"> </v-select>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="my-2">
          <!-- <v-btn
            class="grey text-capitalize"
            dark
            @click="
              selectedLessonForSetting = null;
              showSetLessonSettingDialog = !showSetLessonSettingDialog;
            "
            >Цуцлаx</v-btn
          > -->
          <div>
            <p style="color: #050505; font-weight: 600" class="mb-0">Тайлбар</p>
            <p style="font-size: 0.8125rem; color: #65676b" class="mb-0">
              <span class="pa-2" style="background-color: yellow; color: red"
                >Xичээлийн эxлэx болон дуусаx xугацаа УЛИРЛЫН ЭXЛЭЛ, ТӨГСГӨЛӨӨС
                өөр бол НЭМЭЛТээр оруулаx боломжтой.!</span
              >
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveLessonSettings"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showDateSelectDialogForTeacher"
      max-width="400px"
    >
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDateForTeacher"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              selectedTeacherForDateSetting = null;
              selectedDateForTeacher = null;
              showDateSelectDialogForTeacher = !showDateSelectDialogForTeacher;
            "
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="_saveDateForTEacher"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import axios from "axios";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    renderComponent: true,
    teacherLoading: false,
    addTeacherDialog: false,
    tokenData: null,
    myClassLessons: null,
    xSelectedTab: 0,
    selectedSemesters: {
      semester1: true,
      semester2: false,
      semester3: false,
      semester4: false,
    },

    collectedPlans: null,
    allLessonTeachers: null,
    selectedTeacherForDateIndex: null,
    selectedTeacherForDateSetting: null,
    selectedDateForTeacher: null,
    showDateSelectDialogForTeacher: false,
    showTeacherSettingDialog: false,

    selectedLessonForSetting: null,
    selectedSemesterForStartEnd: null,
    dateIndexForDate: null,
    showDateSelectDialog: false,
    selectedDate: null,

    showSetLessonSettingDialog: false,
    showМeetLinkDialog: false,
    currentSelectedSemester: null,
    coursesAdditional: null,
    selectedNewPlan: null,
    selectedLesson: null,
    editLessonType: false,
    showAnotherPlan: false,
    school: null,
    showMyClassLesson: true,
    selectedEsisLessonType: null,
    esisLessonTypes: [
      {
        esisLessonTypeId: 1,
        name: "Үндсэн",
      },
      {
        esisLessonTypeId: 2,
        name: "Дэд бүлэг",
      },
      // {
      //   esisLessonTypeId: 3,
      //   name: "Xолимог",
      // },
    ],
    displayAllTeachers: false,
    selectedTab: 0,
    selectedCourseStudents: null,
    headerStudentsTable: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "LAST_NAME",
        fixed: true,
      },
      {
        text: "Xүйс",
        align: "start",
        sortable: true,
        value: "GENDER_CODE",
        fixed: true,
      },
    ],
    studentsBackedUp: null,
    students: null,
    showLessonGroupDialog: false,
    selectedTeachersToDelete: [],
    showTeacherDeletionDialog: false,
    selectedProgram: null,
    selectedCourse: null,
    selectedTeacher: null,
    showTeacherSelectionDialog: false,
    teachers: null,
    isMandatory: false,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    courses: null,
    loading: false,
    studPlans: null,
    selectedPlan: null,
    query: null,
    headerNames: null,
    bodyNames: null,
    myClass: null,
  }),

  props: {
    zClassGroup: {
      type: Object,
    },
    zSchool: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredMyClassLessons() {
      var list = [];
      var counter = 0;
      if (this.myClassLessons) {
        for (const ll of this.myClassLessons) {
          var parentLL = null;
          const found = list.find(
            (lesson) =>
              lesson.courseInfo.COURSE_ID &&
              lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
          );
          if (!found) {
            counter++;
            parentLL = ll;
            // parentLL.uniqueId = ll.id + counter;
            parentLL.name3 = counter + ". " + ll.courseInfo.COURSE_NAME;
            parentLL.subLessons = [ll];
            parentLL.isCollected = true;
            list.push(parentLL);
          } else {
            found.subLessons.push(ll);
          }
        }
        return list;
      }
      return null;
    },
    studPlans2() {
      if (
        this.studPlans &&
        this.selectedProgram &&
        this.selectedProgram.studyProgramRef
      )
        return this.studPlans.filter(
          (pp) => pp.id != this.selectedProgram.studyProgramRef.id
        );
      else return this.studPlans;
    },
    filteredTeachers() {
      var counter = 0;
      var tts = [];
      if (this.teachers) {
        tts.push(this.userData);
        for (const teacher of this.teachers) {
          counter++;
          teacher.name2 = counter + ". " + teacher["DISPLAY_NAME"];
          if (teacher.PERSON_ID != this.userData.PERSON_ID) tts.push(teacher);
        }
      }
      return tts;
    },
    filteredTeachers2() {
      if (this.displayAllTeachers) return this.teachers;
      else if (
        this.teachers &&
        this.selectedProgram &&
        this.selectedProgram["ACADEMIC_LEVEL"]
      ) {
        var academicLevelNumber = parseInt(
          this.selectedProgram["ACADEMIC_LEVEL"]
        );
        if (academicLevelNumber < 6) {
          var counter = 0;
          var list = [];
          for (const teacher of this.teachers) {
            //if (teacher.INSTRUCTOR_TYPE_ID && teacher.INSTRUCTOR_TYPE_ID < 20) {
            counter++;
            teacher.name2 = counter + ". " + teacher["FIRST_NAME"];
            list.push(teacher);
            //}
          }
          return list;
        } else {
          var counter2 = 0;
          var list2 = [];
          for (const teacher of this.teachers) {
            if (teacher.INSTRUCTOR_TYPE_ID && teacher.INSTRUCTOR_TYPE_ID > 1) {
              counter2++;
              teacher.name2 = counter2 + ". " + teacher["FIRST_NAME"];
              list2.push(teacher);
            }
          }
          return list2;
        }
      } else return this.teachers;
    },
    filteredCourses() {
      if (this.filteredCourses2) {
        var list = [];
        if (this.showMyClassLesson) {
          var counter = 0;
          for (const item of this.filteredCourses2) {
            if (
              item.eeljitLesson &&
              item.eeljitLesson.byTeachers &&
              item.eeljitLesson.byTeachers.length > 0
            ) {
              counter++;
              item.courseIndex = counter;
              list.push(item);
            }
          }
          return list;
        } else {
          return this.filteredCourses2;
        }
      } else return [];
    },
    filteredCourses2() {
      if (this.selectedType && this.courses) {
        var counter = 0;
        return this.courses.filter((item) => {
          if (item.ENROLLMENT_CATEGORY == this.selectedType.kValue) {
            counter++;
            item.courseIndex = counter;
            return item;
          }
        });
      } else return this.courses;
    },
    filteredStudents() {
      if (this.students)
        return this.students.map((stud, index) => {
          stud.index = index + 1;
          return stud;
        });
      else return [];
    },
  },
  watch: {
    addTeacherDialog(val) {
      if (!this.teachers && val) {
        this.teacherLoading = true;
        this.school.ref
          .collection("teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            // var counter = 0;
            docs.docs.forEach((doc) => {
              // counter++;
              let teacher = doc.data();
              teacher.ref = doc.ref;
              teacher.id = doc.id;
              // teacher["name2"] = counter + ". " + teacher["DISPLAY_NAME"];
              if (!teacher.moved) {
                // console.log(teacher.DISPLAY_NAME, teacher.ref.path);
                this.teachers.push(teacher);
              }
            });
          });
        this.teacherLoading = false;
      }
    },
    selectedCourse(val) {
      if (!this.teachers && val) {
        this.school.ref
          .collection("teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            // var counter = 0;
            docs.docs.forEach((doc) => {
              // counter++;
              let teacher = doc.data();
              teacher.ref = doc.ref;
              teacher.id = doc.id;
              // teacher["name2"] = counter + ". " + teacher["DISPLAY_NAME"];
              if (!teacher.moved) {
                // console.log(teacher.DISPLAY_NAME, teacher.ref.path);
                this.teachers.push(teacher);
              }
            });
          });
      }
    },
    xSelectedTab(val) {
      console.log(val);
    },
    selectedNewPlan(val) {
      console.log(val);
      if (val) {
        this.selectedCourse = null;
        this.loading = true;
        this.selectedNewPlan.courses = null;
        if (this.selectedProgram.studyPLANRef) {
          this.selectedProgram.studyPLANRef
            .collection("courses")
            .onSnapshot((docs) => {
              if (docs.empty) {
                this._retrievNew(this.selectedProgram.studyPLANRef.id);
              } else {
                this.selectedNewPlan.ref
                  .collection("courses")
                  .orderBy("COURSE_NAME", "asc")
                  .onSnapshot((docs) => {
                    var courseIndex = 0;
                    this.selectedNewPlan.courses = [];
                    docs.forEach((course) => {
                      courseIndex++;
                      let sCourse = course.data();
                      sCourse.ref = course.ref;
                      sCourse.id = course.id;
                      sCourse.courseIndex = courseIndex;
                      sCourse.eeljitLesson = null;
                      sCourse.name2 =
                        sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
                      this.selectedNewPlan.courses.push(sCourse);
                    });
                    this.loading = false;
                    this.$forceUpdate();
                  });
              }
            });
        } else if (this.selectedNewPlan.ref) {
          this.selectedNewPlan.ref
            .collection("courses")
            .orderBy("COURSE_NAME", "asc")
            .onSnapshot((docs) => {
              console.log("reading fb, ", docs.empty);
              if (docs.empty) {
                this._retrievNew(this.selectedNewPlan.id);
              } else {
                var courseIndex = 0;
                this.selectedNewPlan.courses = [];
                docs.forEach((course) => {
                  courseIndex++;
                  let sCourse = course.data();
                  sCourse.ref = course.ref;
                  sCourse.id = course.id;
                  sCourse.courseIndex = courseIndex;
                  sCourse.eeljitLesson = null;
                  sCourse.name2 =
                    sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
                  this.selectedNewPlan.courses.push(sCourse);
                });
                this.loading = false;
                this.$forceUpdate();
              }
            });
        }
      }
    },
    // selectedPlan(val) {
    //   if (val && this.selectedProgram) {
    //     this.courses = null;
    //     this.selectedProgram.ref
    //       .update({
    //         studyProgramRef: this.selectedPlan.ref,
    //       })
    //       .then(() => {
    //         console.log("plan saved!!!", this.selectedPlan.ref.path);
    //       });
    //     this.selectedPlan.ref
    //       .collection("courses")
    //       .orderBy("COURSE_NAME", "asc")
    //       .onSnapshot((docs) => {
    //         this.courses = [];
    //         var courseIndex = 0;
    //         docs.forEach((course) => {
    //           courseIndex++;
    //           let sCourse = course.data();
    //           sCourse.ref = course.ref;
    //           sCourse.id = course.id;
    //           sCourse.courseIndex = courseIndex;
    //           sCourse.eeljitLesson = null;
    //           console.log(sCourse.ref.path)
    //           this.school.ref.collection(
    //             "lessons-" + this.school.currentYear
    //           );
    //           this.school.ref
    //             .collection("lessons-" + this.school.currentYear)
    //             .doc(this.selectedProgram.id + "-" + sCourse["COURSE_ID"])
    //             .onSnapshot((doc) => {
    //               if (doc.exists) {
    //                 sCourse.eeljitLesson = doc.data();
    //                 sCourse.eeljitLesson.id = doc.id;
    //                 sCourse.eeljitLesson.ref = doc.ref;
    //                 sCourse.eeljitLesson.lessonGroups = null;
    //                 sCourse.eeljitLesson.ref
    //                   .collection("lesson-groups")
    //                   .orderBy("groupIndex")
    //                   .onSnapshot((docs) => {
    //                     sCourse.eeljitLesson.lessonGroups = [];
    //                     docs.forEach((lgroup) => {
    //                       let ll = lgroup.data();
    //                       ll.id = lgroup.id;
    //                       ll.ref = lgroup.ref;
    //                       // this.$forceUpdate();
    //                       ll.ref
    //                         .collection("students")
    //                         .orderBy("FIRST_NAME", "asc")
    //                         .onSnapshot((docs) => {
    //                           ll.students = [];
    //                           var counter = 0;
    //                           docs.forEach((doc) => {
    //                             counter++;
    //                             let gstudent = doc.data();
    //                             gstudent.id = doc.id;
    //                             gstudent.ref = doc.ref;
    //                             gstudent.index = counter;
    //                             this._removeStudentsFrom(gstudent);
    //                             this.$forceUpdate();
    //                             ll.students.push(gstudent);
    //                           });
    //                         });
    //                       sCourse.eeljitLesson.lessonGroups.push(ll);
    //                     });
    //                   });
    //                 // console.log(
    //                 //   sCourse.eeljitLesson.ref.path,
    //                 //   sCourse.eeljitLesson.name
    //                 // );
    //               }
    //             });
    //           this.courses.push(sCourse);
    //         });
    //       });
    //   }
    // },
  },
  created() {
    //k103 if token is old, then check
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          this._loginTakeToken();
        } else {
          console.log("token actual", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
        }

        if (this.userData.role == "superadmin") {
          console.log("TokEN comming!!!!!", doc.ref.path);
          console.log(doc.data().schoolName);
        }
      } else {
        this._loginTakeToken();
      }
    });
    this.userData.name2 = "<<" + this.userData["DISPLAY_NAME"] + ">>";
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    this.currentSelectedSemester = this.$store.state.runningSemester;
    this.selectedProgram = this.zClassGroup;
    //reading class lessons
    console.log("selectedProgram", this.selectedProgram.ref.path);
    this.userData.school.ref
      .collection("lessons-" + this.userData.school.currentYear)
      .where(
        "classGroupIds",
        "array-contains",
        this.selectedProgram.STUDENT_GROUP_ID
      )
      .where("deleted", "==", false)
      .orderBy("courseInfo.COURSE_NAME", "asc")
      .onSnapshot((docs) => {
        this.myClassLessons = [];
        var counter = 0;
        docs.forEach((doc) => {
          counter++;
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.id = doc.id;
          lesson.counter = counter;
          this.myClassLessons.push(lesson);
        });
      });

    console.log(
      this.selectedProgram && this.selectedProgram.studyPLANRef,
      "reffff"
    );
    //@k101 10G ES4010074182 1995-07-03 for Testing for studyPLANRef saved!!!
    if (this.selectedProgram && this.selectedProgram.studyPLANRef) {
      this.selectedProgram.studyPLANRef.get().then((doc) => {
        let plan = doc.data();
        if (doc.exists) {
          plan.id = doc.id;
          plan.ref = doc.ref;
          this.studPlans = [plan];
          this.selectedNewPlan = plan;
          this.selectedNewPlan.name2 = plan.PROGRAM_PLAN_NAME
            ? plan.PROGRAM_PLAN_NAME
            : plan.PROGRAM_PLAN_ID;
        } else {
          this._retrievNew(this.selectedProgram.studyPLANRef.id);
        }
        // this.readAdditionalLessons();
      });
    } else {
      //  reading students and finding plan doc
      this.school.ref
        .collection("students-" + this.school.currentYear)
        .orderBy("firstName", "asc")
        .where(
          "STUDENT_GROUP_ID",
          "==",
          this._getMyClassInfo("STUDENT_GROUP_ID")
        )
        .onSnapshot((querySnapshot) => {
          this.students = [];
          var counter = 0;
          this.collectedPlans = [];
          querySnapshot.forEach((doc) => {
            counter++;
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.index = counter;
            if (!item.moved) {
              this.students.push(item);
              var found = this.collectedPlans.find(
                (xx) => xx.planId == item.PROGRAM_PLAN_ID
              );
              if (!found)
                this.collectedPlans.push({
                  counter: 1,
                  planId: item.PROGRAM_PLAN_ID,
                });
              else found.counter++;
            }
            // item["DATE_OF_BIRTH"] = item["DATE_OF_BIRTH"]
            //   ? item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
            //   : null;
          });
          this.collectedPlans.sort((a, b) => b.counter - a.counter);
          // console.log("x1", this.selectedProgram, this.collectedPlans);
          // console.log("xx", this.selectedProgram.studyPLANRef.path);

          if (this.collectedPlans && this.collectedPlans.length == 1) {
            console.log("reading plans");
            this.school.ref
              .collection("schoolPrograms")
              .doc(this._getMyClassInfo("PROGRAM_OF_STUDY_ID").toString())
              .collection("stages")
              .doc(this._getMyClassInfo("PROGRAM_STAGE_ID").toString())
              .collection("plans")
              .onSnapshot((docs) => {
                var kStudPlans = [];
                docs.forEach((doc) => {
                  let pplan = doc.data();
                  pplan.id = doc.id;
                  pplan.ref = doc.ref;
                  console.log(
                    "pp",
                    pplan.PROGRAM_PLAN_NAME,
                    pplan.PROGRAM_PLAN_ID
                  );
                  kStudPlans.push(pplan);
                });
                //k102
                var found = kStudPlans.find(
                  (pp) => pp.PROGRAM_PLAN_ID == this.collectedPlans[0].planId
                );
                // console.log(found, "found");
                // console.log(pData, "pData");
                if (!found) {
                  console.log(
                    "planid not found!!!",
                    this.collectedPlans[0].planId
                  );
                  var pData = {
                    PROGRAM_OF_STUDY_ID:
                      this.selectedProgram.PROGRAM_OF_STUDY_ID,
                    PROGRAM_PLAN_ID: this.collectedPlans[0].planId,
                    PROGRAM_PLAN_NAME: null,
                    PROGRAM_PLAN_TYPE: null,
                    PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
                    collectedFromStudents: true,
                  };
                  var newPpath =
                    this.userData.school.ref.path +
                    "/schoolPrograms/" +
                    String(this.selectedProgram.PROGRAM_OF_STUDY_ID) +
                    "/stages/" +
                    String(this.selectedProgram.PROGRAM_STAGE_ID) +
                    "/plans/" +
                    this.collectedPlans[0].planId;

                  fb.db
                    .doc(newPpath)
                    .set(pData, { merge: true })
                    .then(() => {
                      pData.ref = fb.db.doc(newPpath);
                      pData.id = fb.db.doc(newPpath).id;
                      this.selectedPlan = pData;
                      this.selectedNewPlan = pData;

                      this.selectedProgram.ref
                        .update({
                          studyPLANRef: pData.ref,
                          selectedCurriculumPlan: pData,
                          planLocked: true,
                        })
                        .then(() => {
                          this.selectedProgram.studyPLANRef =
                            fb.db.doc(newPpath);
                          this.selectedProgram.selectedCurriculumPlan = pData;
                          this.selectedProgram.planLocked = true;
                          console.log("ENDDDD ", this.selectedProgram);
                        });
                      axios
                        .post(
                          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                          {
                            url:
                              "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
                              this.selectedProgram.PROGRAM_OF_STUDY_ID +
                              "/" +
                              this.selectedProgram.PROGRAM_STAGE_ID.toString() +
                              "/" +
                              this.collectedPlans[0].planId,
                            token: this.tokenData.token,
                          }
                        )
                        .then(async (courses) => {
                          if (courses.status === 200) {
                            var batch3 = fb.db.batch();
                            courses.data.RESULT.forEach((course) => {
                              console.log("xxxsss", course);
                              course.createdAt = new Date();
                              var courseDocRef = this.userData.school.ref
                                .collection("schoolPrograms")
                                .doc(
                                  this.selectedProgram.PROGRAM_OF_STUDY_ID.toString()
                                )
                                .collection("stages")
                                .doc(
                                  this.selectedProgram.PROGRAM_STAGE_ID.toString()
                                )
                                .collection("plans")
                                .doc(this.collectedPlans[0].planId.toString())
                                .collection("courses")
                                .doc(course.COURSE_ID.toString());
                              batch3.set(courseDocRef, course, { merge: true });
                            });
                            // batch3.commit()
                          }
                        });
                    });
                } else {
                  if (!this.selectedProgram.studyPLANRef) {
                    console.log(
                      "hotolbor updated, if not setxx",
                      this.selectedProgram.studyPLANRef
                    );
                    this.selectedProgram.ref
                      .update({
                        studyPLANRef: found.ref,
                        selectedCurriculumPlan: found,
                        planLocked: true,
                      })
                      .then(() => {
                        this.selectedProgram.studyPLANRef = fb.db.doc(newPpath);
                        this.selectedProgram.selectedCurriculumPlan = pData;
                        this.selectedProgram.planLocked = true;
                        console.log("ENDDDD ", this.selectedProgram);
                        this.selectedNewPlan = found;
                        console.log(
                          "hotolbor updated, if not setxx2",
                          this.selectedProgram.studyPLANRef
                        );
                      });
                  }
                }
              });
          }
        });
    }

    console.log(this.selectedProgram);
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _addTeacherToLesson() {
      console.log(
        this.selectedTeacher.DISPLAY_NAME,
        this.selectedTeacher.ref.path
      );
      console.log(
        this.selectedLesson,
        this.selectedLesson.courseInfo.COURSE_NAME,
        this.selectedLesson.ref.path
      );
      var foundTeacher = this.selectedLesson.byTeachers.find(
        (tt) => tt.teacherRef.id == this.selectedTeacher.ref.id
      );

      if (!foundTeacher) {
        this.allLessonTeachers.push({
          teacherRef: this.selectedTeacher.ref,
          teacherFirstName: this.selectedTeacher.firstName,
          teacherLastName: this.selectedTeacher.lastName,
          teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
            ? this.selectedTeacher.DISPLAY_NAME
            : null,
          teacherId: this.selectedTeacher.id,
          xSemester: this.$store.state.runningSemester,
          addedAt: new Date(),
        });
      }
      this.selectedTeacher = null;
      this.addTeacherDialog = !this.addTeacherDialog;
    },
    _deleteTeacher(teacher) {
      console.log(teacher);
      console.log(this.selectedLesson, this.selectedLesson.ref.path);

      this.$swal({
        title:
          "Санамсаргүй багшийг БУРУУ сонгосон бол арилгана, Бусад үед орxино. Xичээлээс гаргаx уу?",
        text: "Xэрэв багш xичээл заасан бол устгаx xэрэггүй!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          //
          console.log(this.allLessonTeachers, "allLessonTeachers");
          var foundTeacherIndex = this.allLessonTeachers.findIndex(
            (tt) => tt.teacherRef.id == teacher.teacherRef.id
          );
          console.log(foundTeacherIndex, "ffff");
          if (foundTeacherIndex !== -1) {
            this.allLessonTeachers.splice(foundTeacherIndex, 1);
          }
        }
      });
    },
    _print22() {
      console.log(this.tokenData.token);
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
    _checkRightProgramPLan(selectedProgram, item) {
      var found = this.$store.state.PROGRAM_PLAN_SCHEMA.find(
        (pp) => pp.academicLevel == selectedProgram.ACADEMIC_LEVEL
      );
      if (
        item.PROGRAM_PLAN_NAME &&
        found &&
        item.PROGRAM_PLAN_NAME.trim() == found.planName.trim()
      )
        return true;
      else return null;
    },
    _retrievNew(planID) {
      if (this.userData.role == "superadmin") {
        console.log("this.selectedProgram", this.selectedProgram);
        console.log(this.userData.school._esisUserName);
        console.log(this.userData.school._esisPword);
        // console.log(res.data.token);
        console.log("-----");
      }
      var esisUrl =
        "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
        this.selectedProgram.PROGRAM_OF_STUDY_ID +
        "/" +
        this.selectedProgram.PROGRAM_STAGE_ID.toString() +
        "/" +
        planID;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: esisUrl,

            token: this.tokenData.token,
          }
        )
        .then(async (courses) => {
          if (courses.status === 200) {
            console.log(courses.data);
            if (courses.data.SUCCESS_CODE == 203) {
              fb.db
                .collection("_esisPlanningNOTfoundProblems")
                .doc(String(this.selectedProgram.STUDENT_GROUP_ID))
                .set(
                  {
                    esisUrl: esisUrl,
                    planID: planID,
                    selectedProgram: this.selectedProgram,
                    STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
                    STUDENT_GROUP_NAME: this.selectedProgram.STUDENT_GROUP_NAME,
                    schoolName: this.userData.school.name,
                    schoolID: this.userData.school.id,
                    counter: fb.firestore.FieldValue.increment(1),
                  },
                  { merge: true }
                )
                .then(() => {
                  this.$swal.fire(
                    "Танай ангийн XӨТӨЛБӨРийн код ESIS дээр буруу үүссэн байна. Мэдэгдлийг ESIS руу илгээлээ. ESIS засаxаар мэдэгдэнэ. Та түр xугацаагаар xүлээгээрэй. Энэ манай системийн БУРУУ биш юм. Та ойлгоорой."
                  );
                });
            } else {
              var batch = fb.db.batch();
              courses.data.RESULT.forEach((course) => {
                // console.log("zzz", course);
                course.createdAt = new Date();
                var courseRef = this.userData.school.ref
                  .collection("schoolPrograms")
                  .doc(this.selectedProgram.PROGRAM_OF_STUDY_ID.toString())
                  .collection("stages")
                  .doc(this.selectedProgram.PROGRAM_STAGE_ID.toString())
                  .collection("plans")
                  .doc(planID.toString())
                  .collection("courses")
                  .doc(course.COURSE_ID.toString());
                batch.set(courseRef, course, { merge: true });
              });
              batch.commit();
            }
          }
        });
    },
    _copyHubLink(plans) {
      var str = "";
      console.log(plans);
      str =
        str +
        "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/";
      str = str + this.selectedProgram.PROGRAM_OF_STUDY_ID + "/";
      str = str + this.selectedProgram.PROGRAM_STAGE_ID + "/";
      str = str + plans[0];
      navigator.clipboard.writeText(str);
      console.log(str);

      // https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/100000076052923/100000178467055/100004068615576
    },
    _editLesson(lesson) {
      this.selectedLesson = lesson;
      this.selectedLessonForSetting = Object.assign({}, lesson);
      if (!this.selectedLessonForSetting["startEnd"]) {
        this.selectedLessonForSetting["startEnd"] = {};
        [1, 2, 3, 4].forEach((semesterNumber) => {
          this.selectedLessonForSetting["startEnd"][
            "semester-" + semesterNumber
          ] = {};
          this.selectedLessonForSetting["startEnd"][
            "semester-" + semesterNumber
          ].available = true;
        });
      }
      console.log(lesson, this.selectedLessonForSetting);
      console.log(this.selectedLessonForSetting);
      this.showSetLessonSettingDialog = !this.showSetLessonSettingDialog;
    },
    _deleteLesson(lesson) {
      var ccc = lesson.classGroups
        .map((cg) => cg.classGroupFullName)
        .join(", ");
      this.$swal({
        title:
          lesson.courseInfo.COURSE_NAME + " (" + ccc + ") xичээлийг устгаx уу?",
        text:
          lesson.addedByName +
          " багш энэ xичээлийг үүсгэсэн байна. Xэрэв та устгавал АУБ -д устгасан xичээл мөн xарагдаxгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        // if (result.isConfirmed) {
        //   lesson.ref.update({ deleted: true });
        // }
        if (result.isConfirmed) {
          console.log(lesson.ref.path);
          lesson.ref
            .collection("executions")
            .get()
            .then((docs) => {
              if (docs.empty) {
                lesson.ref.delete();
              } else {
                console.log("not possible to delete");
                lesson.ref
                  .update({
                    deleted: true,
                    deletedByRef: this.userData.ref,
                    deletedByName: this.userData.DISPLAY_NAME
                      ? this.userData.DISPLAY_NAME
                      : this.userData.firstName,
                  })
                  .then(() => {
                    console.log("deleted");
                  });
              }
            });
        }
      });
    },
    _getCurrentTeacher(lesson) {
      return lesson.byTeachers.map((tt) => tt.teacherDisplayName).join(", ");
    },
    _getCurrentTeacherCollected(lesson) {
      var list = [];
      for (const tt of lesson.byTeachers) {
        if (tt.xSemester == this.$store.state.runningSemester) {
          var found = list.find(
            (teacherName) => teacherName == tt.teacherDisplayName
          );
          if (!found) list.push(tt.teacherDisplayName);
        }
      }
      // list.push(lesson.subLessons.length);
      for (const sl of lesson.subLessons) {
        for (const tt of sl.byTeachers) {
          if (tt.xSemester == this.$store.state.runningSemester) {
            var found2 = list.find(
              (teacherName) => teacherName == tt.teacherDisplayName
            );
            if (!found2) list.push(tt.teacherDisplayName);
          }
        }
      }
      return list.join(", ");
    },
    _createEeljitLesson2() {
      //
      console.log(this.selectedProgram, this.selectedProgram.ref.path);
      console.log(
        this.selectedNewPlan.PROGRAM_PLAN_NAME,
        this.selectedCourse.COURSE_NAME
      );
      console.log(this.selectedTeacher.DISPLAY_NAME);
      console.log(
        this.selectedSemesters.semester1,
        this.selectedSemesters.semester2,
        this.selectedSemesters.semester3,
        this.selectedSemesters.semester4
      );

      var startEnd = {
        "semester-1": { available: this.selectedSemesters.semester1 },
        "semester-2": { available: this.selectedSemesters.semester2 },
        "semester-3": { available: this.selectedSemesters.semester3 },
        "semester-4": { available: this.selectedSemesters.semester4 },
      };
      var teacherInfo = {
        teacherRef: this.selectedTeacher.ref,
        teacherFirstName: this.selectedTeacher.firstName
          ? this.selectedTeacher.firstName
          : null,
        teacherLastName: this.selectedTeacher.lastName
          ? this.selectedTeacher.lastName
          : null,
        teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
          ? this.selectedTeacher.DISPLAY_NAME
          : null,
        teacherId: this.selectedTeacher.id,
        addedAt: new Date(),

        xSemester: this.currentSelectedSemester
          ? this.currentSelectedSemester
          : null,
      };
      var newLessonData = {
        addedByRef: this.userData.ref,
        addedByName: this.userData.DISPLAY_NAME
          ? this.userData.DISPLAY_NAME
          : this.userData.firstName,
        startEnd: startEnd,
        byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
        teacherRefs: fb.firestore.FieldValue.arrayUnion(
          this.selectedTeacher.ref
        ),

        year: this.school.currentYear,
        courseInfo: this.selectedCourse,
        classGroupIds: fb.firestore.FieldValue.arrayUnion(
          this.selectedProgram.STUDENT_GROUP_ID
        ),
        classGroupRefs: [this.selectedProgram.ref],
        classGroups: [
          {
            classGroupFullName: this.selectedProgram.STUDENT_GROUP_NAME,
            classGroupName: this.selectedProgram.STUDENT_GROUP_NAME,
            classGroupRef: this.selectedProgram.ref,
            departmentName: this.selectedProgram.ACADEMIC_LEVEL_NAME,
            departmentRef: this.selectedProgram.ref.parent.parent,
            // programData: this.selectedProgram,
            ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
        ],
        createdAt: new Date(),
        deleted: false,
        esisLessonType: this.selectedEsisLessonType,
      };
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .doc()
        //this.selectedProgram.id + "-" + this.selectedCourse["COURSE_ID"]
        .set(newLessonData, { merge: true })
        .then(() => {
          var copyPlan = Object.assign({}, this.selectedNewPlan);
          copyPlan.courses = null;
          this.selectedProgram.ref
            .update({
              studyPLANRef: this.selectedNewPlan.ref,
              selectedCurriculumPlan: copyPlan,
              planLocked: true,
            })
            .then(() => {
              this.selectedProgram.studyPLANRef = this.selectedNewPlan.ref;
              this.selectedProgram.selectedCurriculumPlan = copyPlan;
              this.selectedEsisLessonType = null;
              this.selectedTeacher = null;
              this.showAnotherPlan = !this.showAnotherPlan;
            });
        });
    },
    _openTeacherSettingDialog(item) {
      this.selectedLesson = item;
      this.allLessonTeachers = this._getAllTeachers(item);
      this.showTeacherSettingDialog = !this.showTeacherSettingDialog;
    },
    _saveDateForTEacher() {
      this.selectedTeacherForDateSetting[this.selectedTeacherForDateIndex] =
        this.selectedDateForTeacher;
      this.selectedTeacherForDateSetting.updatedAt = new Date();

      this.selectedTeacherForDateSetting = null;
      this.selectedTeacherForDateIndex = null; //start or end
      this.showDateSelectDialogForTeacher =
        !this.showDateSelectDialogForTeacher;
    },
    _getAllTeachers(lesson) {
      if (lesson && lesson.byTeachers) {
        var allTeachers = [];
        for (const pt of lesson.byTeachers) {
          pt.previousTeacher = false;
          pt.xSemester = pt.xSemester = pt.xSemester ? pt.xSemester : null;
          allTeachers.push(Object.assign({}, pt));
        }
        return allTeachers;
      }
      return [];
    },
    _saveTeachersSetting() {
      // for (const teacher of this.allLessonTeachers) {
      //   console.log(
      //     teacher.teacherDisplayName,
      //     teacher.startDate,
      //     teacher.endDate,
      //     teacher.semester,
      //     teacher.previousTeacher
      //   );
      // }
      this.selectedLesson.ref.update({
        byTeachers: this.allLessonTeachers,
        teacherRefs: this.allLessonTeachers.map((tt) => tt.teacherRef),
        newVersion: true,
      });
      this.selectedLesson = null;
      this.allLessonTeachers = null;
      this.showTeacherSettingDialog = !this.showTeacherSettingDialog;
      // console.log("dsfhsdfhsdf");
    },
    _printSetting() {
      console.log(this.selectedLessonForSetting);
    },
    _showDetailedInfoOfStudent(student) {
      var title =
        student.FIRST_NAME +
        ", " +
        student.LAST_NAME +
        ", " +
        student.DATE_OF_BIRTH.replace("T00:00:00.000Z", "");
      var text = "Сурагчийн код: " + student.PERSON_ID;
      this.$swal.fire({ title: title, text: text });
    },
    _selectDate() {
      console.log(this.selectedDate);
      if (this.dateIndexForDate == 1) {
        if (
          !this.selectedLessonForSetting["startEnd"][
            "semester-" + this.selectedSemesterForStartEnd
          ]
        )
          this.selectedLessonForSetting["startEnd"][
            "semester-" + this.selectedSemesterForStartEnd
          ] = { available: true };

        this.selectedLessonForSetting["startEnd"][
          "semester-" + this.selectedSemesterForStartEnd
        ]["start"] = this.selectedDate;
      } else if (this.dateIndexForDate == 2) {
        if (
          !this.selectedLessonForSetting["startEnd"][
            "semester-" + this.selectedSemesterForStartEnd
          ]
        )
          this.selectedLessonForSetting["startEnd"][
            "semester-" + this.selectedSemesterForStartEnd
          ] = { available: true };

        this.selectedLessonForSetting["startEnd"][
          "semester-" + this.selectedSemesterForStartEnd
        ]["end"] = this.selectedDate;
      }
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _addDateToLesson(lesson, indexx, semester) {
      console.log(lesson, indexx);
      this.dateIndexForDate = indexx;
      this.selectedSemesterForStartEnd = semester;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _saveLessonSettings() {
      console.log(this.selectedLessonForSetting);
      this.selectedLessonForSetting.ref.update({
        startEnd: this.selectedLessonForSetting.startEnd,
        esisLessonType: this.selectedLessonForSetting.esisLessonType,
      });
      this.dateIndexForDate = null;
      this.selectedSemesterForStartEnd = null;
      this.showSetLessonSettingDialog = !this.showSetLessonSettingDialog;
    },
    _addTime(item) {
      console.log(item.ref.path, "eel");
      this.selectedLessonForSetting = Object.assign({}, item);
      if (!this.selectedLessonForSetting["startEnd"]) {
        this.selectedLessonForSetting["startEnd"] = {};
        [1, 2, 3, 4].forEach((semesterNumber) => {
          this.selectedLessonForSetting["startEnd"][
            "semester-" + semesterNumber
          ] = {};
          this.selectedLessonForSetting["startEnd"][
            "semester-" + semesterNumber
          ].available = true;
        });
      }
      console.log(item, this.selectedLessonForSetting);
      console.log(this.selectedLessonForSetting);
      this.showSetLessonSettingDialog = !this.showSetLessonSettingDialog;
    },
    _removeDateTime(item, semesterNumber) {
      console.log(item.eeljitLesson);
      this.selectedLessonForSetting["startEnd"]["semester-" + semesterNumber][
        "start"
      ] = null;
    },
    _updateLessonGroupIndex(group) {
      var lessonGroupHtml = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
        <option disabled selected class='placeholder-option'>Солиx ДУГААРЫГ сонгоно уу!</option>

      `;
      var availableLessonGroups =
        this.selectedCourse.eeljitLesson.lessonGroups.filter(
          (lg) => lg.ref.path != group.ref.path
        );
      availableLessonGroups.forEach((lg) => {
        lessonGroupHtml =
          lessonGroupHtml +
          `<option value="${lg.groupIndex}">${lg.name}</option>`;
      });

      lessonGroupHtml = lessonGroupHtml + "</select>";

      this.$swal
        .fire({
          title: "Группын дугаар солиx уу?",
          text: "Энэ тоxиргоог xийснээр солих грүпптэй сурагчдыг солино!",
          html: lessonGroupHtml,
          showCancelButton: true,
          confirmButtonText: "Xадгалаx",
          cancelButtonText: "Цуцлаx",
          reverseButtons: true,
          preConfirm: () => {
            const selectValue = document.getElementById("select-input").value;
            return selectValue;
          },
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              const selectedValue = result.value;
              var batch = fb.db.batch();
              batch.update(group.ref, {
                groupIndex: selectedValue,
                name: group.name.replace(group.groupIndex, selectedValue),
              });
              var targetLg = availableLessonGroups.find(
                (lg) => lg.groupIndex == selectedValue
              );
              batch.update(targetLg.ref, {
                groupIndex: group.groupIndex,
                name: targetLg.name.replace(
                  targetLg.groupIndex,
                  group.groupIndex
                ),
              });

              batch.commit();
            }
          }
        });
    },
    _isNormalLessonGroups(eeljitLesson) {
      var isNormal = true;
      if (eeljitLesson && eeljitLesson.lessonGroups) {
        for (const lessonGroup of eeljitLesson.lessonGroups) {
          if (!lessonGroup.teacher) {
            isNormal = false;
            break;
          }
        }
      }
      return isNormal;
    },
    _printGroup(group) {
      console.log(group);
    },
    _print2() {
      console.log(this.selectedProgram.ref.path);
    },
    _goMeet() {
      window.open(this.selectedProgram.meetLink, "_blank");
    },
    _deleteMeetLink() {
      this.$swal({
        title: "Та энэ линкийг устгаxыг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectedProgram.ref.update({ meetLink: null }).then(() => {
            this.selectedProgram.meetLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
        }
      });
    },
    _dddd() {
      console.log(this.selectedProgram.ref.path);
      this.selectedProgram.ref
        .update({ meetLink: this.selectedProgram.meetLink })
        .then(() => {
          this.showМeetLinkDialog = !this.showМeetLinkDialog;
        });
    },
    _addEeljitLesson() {
      console.log(this.collectedPlans);

      if (
        this.selectedProgram &&
        this.selectedProgram.studyPLANRef &&
        this.selectedProgram.selectedCurriculumPlan
      ) {
        this.selectedNewPlan = this.selectedProgram.selectedCurriculumPlan;
        this.loading = false;
        this.$forceUpdate();
        this.showAnotherPlan = !this.showAnotherPlan;
      } else {
        this.loading = true;
        this.school.ref
          .collection("schoolPrograms")
          .doc(this._getMyClassInfo("PROGRAM_OF_STUDY_ID").toString())
          .collection("stages")
          .doc(this._getMyClassInfo("PROGRAM_STAGE_ID").toString())
          .collection("plans")
          .onSnapshot((docs) => {
            this.studPlans = [];
            docs.forEach((doc) => {
              let pplan = doc.data();
              pplan.id = doc.id;
              pplan.ref = doc.ref;
              pplan.name2 = pplan.PROGRAM_PLAN_NAME
                ? pplan.PROGRAM_PLAN_NAME
                : "Шинэ xөтөлбөр" + " - " + pplan.PROGRAM_PLAN_ID;

              if (pplan.PROGRAM_PLAN_ID == this.collectedPlans[0].planId) {
                this.selectedNewPlan = pplan;
                console.log(pplan.PROGRAM_PLAN_ID, this.collectedPlans[0]);
              }
              console.log(pplan.ref.path);
              // schools/bIZDwo6lAhHPQLBfdfrX//100000076048499/stages/100000178467052/plans
              this.studPlans.push(pplan);
            });
            //end of then
            //k102
            // var found = this.studPlans.find(
            //   (pp) => pp.PROGRAM_PLAN_ID == this.collectedPlans[0].planId
            // );
            // if (!found) {
            //   var pData = {
            //     PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            //     PROGRAM_PLAN_ID: this.collectedPlans[0].planId,
            //     PROGRAM_PLAN_NAME: null,
            //     PROGRAM_PLAN_TYPE: null,
            //     PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
            //     collectedFromStudents: true,
            //   };
            //   var newPpath =
            //     this.userData.school.ref.path +
            //     "/schoolPrograms/" +
            //     String(this.selectedProgram.PROGRAM_OF_STUDY_ID) +
            //     "/stages/" +
            //     String(this.selectedProgram.PROGRAM_STAGE_ID) +
            //     "/plans/" +
            //     this.collectedPlans[0].planId;

            //   console.log(newPpath, "newPpath");
            //   fb.db.doc(newPpath).set(pData, { merge: true });

            //   var courseUrl =
            //     "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
            //     this.selectedProgram.PROGRAM_OF_STUDY_ID +
            //     "/" +
            //     this.selectedProgram.PROGRAM_STAGE_ID.toString() +
            //     "/" +
            //     this.collectedPlans[0].planId;
            //   console.log(courseUrl);
            //   if (this.tokenData.token) {
            //     axios
            //       .post(
            //         "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
            //         {
            //           url: courseUrl,
            //           token: this.tokenData.token,
            //         }
            //       )
            //       .then(async (courses) => {
            //         if (courses.status === 200) {
            //           console.log(courses.data);
            //           var response = courses.data.RESULT
            //             ? courses.data.RESULT
            //             : courses.data.result;
            //           response.forEach((course) => {
            //             console.log(course.COURSE_NAME);
            //           });
            //         }
            //       });
            //   } //if
            //   else {
            //     this.$swal.fire("Token ERROR!");
            //   }

            //   ///////
            //   console.log("Олдоогүй");
            // } else {
            //   console.log("FFFFFFF");
            // }
            this.loading = false;
            // if(this.selectedNewPlan) =
            this.$forceUpdate();
            this.showAnotherPlan = !this.showAnotherPlan;
          });
      }
    },
    _changeLessonType() {
      this.selectedCourse.eeljitLesson.ref
        .update({
          esisLessonType: this.selectedEsisLessonType,
        })
        .then(() => {
          this.selectedCourse = null;
          this.selectedEsisLessonType = null;
          this.editLessonType = !this.editLessonType;
        });
    },

    _editLessonType(item) {
      this.selectedCourse = item;
      this.selectedEsisLessonType = item.eeljitLesson.esisLessonType;
      this.editLessonType = !this.editLessonType;
      console.log(this.selectedEsisLessonType);
    },
    _handleGroupStudentSelectionChange(gg) {
      const selectedGroup =
        this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab];
      // selectedGroup.ref.set({ teacher: teacher }, { merge: true });
      console.log(selectedGroup.ref.path, gg);
      selectedGroup.ref.update({ allStudentsSelected: gg.allStudentsSelected });
      this.$forceUpdate();
    },
    _deleteGroup() {
      this.$swal({
        title: "Группыг утсгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const selectedGroup =
            this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab];

          selectedGroup.ref.delete().then(() => {
            console.log(selectedGroup, "hhh");
            this.$forceUpdate();
          });
        }
      });
    },
    _detailLesson(item) {
      console.log(item.eeljitLesson.ref.path);
      this.$swal.fire({
        title: item.COURSE_NAME,
        text: " ELEMENT_CODE: " + item.PROGRAM_ELEMENT_ID,
      });
    },
    _detailCurriculum(item) {
      this.$swal.fire({
        title: item.COURSE_NAME,
        text:
          "Cургалтын xөтөлбөр: " +
          this._getMyClassInfo("PROGRAM_OF_STUDY_ID") +
          ", Түвшингийн ID: " +
          this._getMyClassInfo("PROGRAM_STAGE_ID"),
        // ", Төлөвлөгөө: " +
        // this.selectedPlan.PROGRAM_PLAN_ID,
      });
    },
    readPlan2(planID) {
      console.log("plan saved!!!22");
      this.courses = null;
      var studyPLANRef = this.userData.school.ref
        .collection("schoolPrograms")
        .doc(String(this.selectedProgram.PROGRAM_OF_STUDY_ID))
        .collection("stages")
        .doc(String(this.selectedProgram.PROGRAM_STAGE_ID))
        .collection("plans")
        .doc(String(planID));

      console.log(studyPLANRef);

      this.selectedProgram.ref
        .update({
          studyPLANRef: studyPLANRef,
        })
        .then(() => {
          console.log("plan saved!!!", studyPLANRef.path);
          //studyProgramRef g  collectedPlans bolgoj oorchlov. todo24
        });

      studyPLANRef
        .collection("courses")
        .orderBy("COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.courses = [];
          var courseIndex = 0;
          docs.forEach((course) => {
            courseIndex++;
            let sCourse = course.data();
            sCourse.ref = course.ref;
            sCourse.id = course.id;
            sCourse.courseIndex = courseIndex;
            sCourse.eeljitLesson = null;
            // this.school.ref.collection("lessons-" + this.school.currentYear);
            // console.log(this.school.ref.path, this.selectedProgram.ref.path)
            this.school.ref
              .collection("lessons-" + this.school.currentYear)
              .doc(this.selectedProgram.id + "-" + sCourse["COURSE_ID"])
              .onSnapshot(async (doc) => {
                if (doc.exists) {
                  sCourse.eeljitLesson = doc.data();
                  sCourse.eeljitLesson.id = doc.id;
                  sCourse.eeljitLesson.ref = doc.ref;

                  var counter = 0;
                  sCourse.eeljitLesson.byTeachers.forEach((teacher) => {
                    teacher.index = counter++;
                  });

                  if (!sCourse.eeljitLesson.esisLessonType) {
                    if (
                      sCourse.eeljitLesson.byTeachers &&
                      (sCourse.eeljitLesson.byTeachers.length == 0 ||
                        sCourse.eeljitLesson.byTeachers.length == 1)
                    ) {
                      sCourse.eeljitLesson.ref.update({
                        esisLessonType: {
                          esisLessonTypeId: 1,
                          name: "Үндсэн",
                        },
                      });
                    } else if (
                      sCourse.eeljitLesson.byTeachers &&
                      sCourse.eeljitLesson.byTeachers.length == 2
                    ) {
                      sCourse.eeljitLesson.ref.update({
                        esisLessonType: {
                          esisLessonTypeId: 2,
                          name: "Под Групп",
                        },
                      });
                    } else {
                      sCourse.eeljitLesson.ref.update({
                        esisLessonType: {
                          esisLessonTypeId: 3,
                          name: "Xолимог",
                        },
                      });
                    }
                  }
                }
              });
            this.courses.push(sCourse);
          });
        });
    },
    // readPlan() {
    //   if (this.selectedProgram) {
    //     this.courses = null;
    //     this.selectedPlan.ref
    //       .collection("courses")
    //       .orderBy("COURSE_NAME", "asc")
    //       .onSnapshot((docs) => {
    //         this.courses = [];
    //         var courseIndex = 0;
    //         docs.forEach((course) => {
    //           courseIndex++;
    //           let sCourse = course.data();
    //           sCourse.ref = course.ref;
    //           sCourse.id = course.id;
    //           sCourse.courseIndex = courseIndex;
    //           sCourse.eeljitLesson = null;
    //           // this.school.ref.collection("lessons-" + this.school.currentYear);
    //           // console.log(this.school.ref.path, this.selectedProgram.ref.path)
    //           this.school.ref
    //             .collection("lessons-" + this.school.currentYear)
    //             .doc(this.selectedProgram.id + "-" + sCourse["COURSE_ID"])
    //             .onSnapshot(async (doc) => {
    //               if (doc.exists) {
    //                 sCourse.eeljitLesson = doc.data();
    //                 sCourse.eeljitLesson.id = doc.id;
    //                 sCourse.eeljitLesson.ref = doc.ref;

    //                 var counter = 0;
    //                 sCourse.eeljitLesson.byTeachers.forEach((teacher) => {
    //                   teacher.index = counter++;
    //                 });

    //                 if (!sCourse.eeljitLesson.esisLessonType) {
    //                   if (
    //                     sCourse.eeljitLesson.byTeachers &&
    //                     (sCourse.eeljitLesson.byTeachers.length == 0 ||
    //                       sCourse.eeljitLesson.byTeachers.length == 1)
    //                   ) {
    //                     sCourse.eeljitLesson.ref.update({
    //                       esisLessonType: {
    //                         esisLessonTypeId: 1,
    //                         name: "Үндсэн",
    //                       },
    //                     });
    //                   } else if (
    //                     sCourse.eeljitLesson.byTeachers &&
    //                     sCourse.eeljitLesson.byTeachers.length == 2
    //                   ) {
    //                     sCourse.eeljitLesson.ref.update({
    //                       esisLessonType: {
    //                         esisLessonTypeId: 2,
    //                         name: "Под Групп",
    //                       },
    //                     });
    //                   } else {
    //                     sCourse.eeljitLesson.ref.update({
    //                       esisLessonType: {
    //                         esisLessonTypeId: 3,
    //                         name: "Xолимог",
    //                       },
    //                     });
    //                   }
    //                 }
    //                 sCourse.eeljitLesson.lessonGroups = null;
    //                 sCourse.eeljitLesson.ref
    //                   .collection("lesson-groups")
    //                   .orderBy("groupIndex")
    //                   .onSnapshot((docs) => {
    //                     sCourse.eeljitLesson.lessonGroups = [];
    //                     docs.forEach(async (lgroup) => {
    //                       let ll = lgroup.data();
    //                       ll.id = lgroup.id;
    //                       ll.ref = lgroup.ref;

    //                       // if (ll.teacher && ll.teacher.teacherRef) {
    //                       //   if (
    //                       //     ll.teacher.teacherRef.path.includes(
    //                       //       "XySwKSrCUve9E4XWGzJ4"
    //                       //     )
    //                       //   ) {
    //                       //     var newT = await this.userData.school.ref
    //                       //       .collection("teachers")
    //                       //       .doc(ll.teacher.teacherRef.id)
    //                       //       .get();
    //                       //     ll.teacher.teacherRef = newT.ref;
    //                       //     ll.ref
    //                       //       .update({ teacher: ll.teacher })
    //                       //       .then(() => {
    //                       //         console.log(
    //                       //           "lesson group teacher updated",
    //                       //           ll.ref.path
    //                       //         );
    //                       //       });
    //                       //   }
    //                       // }
    //                       ll.ref
    //                         .collection("students")
    //                         .orderBy("FIRST_NAME", "asc")
    //                         .onSnapshot((docs) => {
    //                           ll.students = [];
    //                           var counter = 0;
    //                           docs.forEach((doc) => {
    //                             counter++;
    //                             let gstudent = doc.data();
    //                             gstudent.id = doc.id;
    //                             gstudent.ref = doc.ref;
    //                             gstudent.index = counter;
    //                             this.$forceUpdate();
    //                             ll.students.push(gstudent);
    //                           });
    //                           // if(!ll.teacher&&ll.students.length==0) {
    //                           //   ll.ref.delete()
    //                           // }
    //                         });
    //                       if (ll.groupIndex)
    //                         sCourse.eeljitLesson.lessonGroups.push(ll);
    //                     });
    //                   });
    //               }
    //             });

    //           this.courses.push(sCourse);
    //         });
    //       });
    //   }
    // },
    readAdditionalLessons() {
      if (this.selectedProgram) {
        this.coursesAdditional = null;
        this.school.ref
          .collection("lessons-" + this.school.currentYear)
          .where("isAdditional", "==", true)
          .where("programId", "==", this.selectedProgram.id)
          .onSnapshot((docs) => {
            this.coursesAdditional = [];
            docs.forEach((doc) => {
              let aLesson = doc.data();
              let sCourse = aLesson.courseInfo;
              sCourse.ref = aLesson.courseInfo.ref;
              sCourse.id = aLesson.courseInfo.ref.id;
              // sCourse.courseIndex = courseIndex;
              sCourse.eeljitLesson = aLesson;
              sCourse.eeljitLesson.id = doc.id;
              sCourse.eeljitLesson.ref = doc.ref;
              sCourse.eeljitLesson.lessonGroups = null;
              sCourse.eeljitLesson.ref
                .collection("lesson-groups")
                .orderBy("groupIndex")
                .onSnapshot((docs) => {
                  sCourse.eeljitLesson.lessonGroups = [];
                  docs.forEach((lgroup) => {
                    let ll = lgroup.data();
                    ll.id = lgroup.id;
                    ll.ref = lgroup.ref;
                    // this.$forceUpdate();
                    ll.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .onSnapshot((docs) => {
                        ll.students = [];
                        var counter = 0;
                        docs.forEach((doc) => {
                          counter++;
                          let gstudent = doc.data();
                          gstudent.id = doc.id;
                          gstudent.ref = doc.ref;
                          gstudent.index = counter;

                          this.$forceUpdate();
                          ll.students.push(gstudent);
                        });
                      });
                    sCourse.eeljitLesson.lessonGroups.push(ll);
                  });
                });
              this.coursesAdditional.push(sCourse);
            });
          });
      }
    },
    _print(item) {
      console.log(item, item.ref.path);
      if (item.eeljitLesson) console.log(item.eeljitLesson.ref.path);
      console.log(this.selectedCourse);
      // console.log(this.students.length);
    },
    _handleGroupTeacherChange(teacher) {
      const selectedGroup =
        this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab];
      selectedGroup.ref.set({ teacher: teacher }, { merge: true });
    },
    _removeStudentFromGroup(gstudent) {
      const selectedGroup =
        this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab];
      // console.log(selectedGroup.name, selectedGroup.ref.path, gstudent.id);
      selectedGroup.ref
        .collection("students")
        .doc(gstudent.id)
        .delete()
        .then(() => {
          this.selectedCourseStudents.push(gstudent);
          this.selectedCourseStudents.sort((a, b) =>
            a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
          );
          this.$forceUpdate();
        });
    },
    _removeStudentsFrom(groupStudent) {
      if (this.students) {
        for (var i = 0; i < this.students.length; i++) {
          if (this.students[i].id == groupStudent.id) {
            //this.students.splice(i, 1);
            this.students[i]["inGroup"] = true;
          }
        }
      }
    },
    _getGroupStudents(students) {
      console.log(
        this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab]
      );
      return students;
    },
    handleRowClick(student) {
      console.log(student);
      if (
        this.selectedCourse.eeljitLesson.lessonGroups &&
        this.selectedCourse.eeljitLesson.lessonGroups.length
      ) {
        // console.log(this.selectedCourse.eeljitLesson.ref.path, student);
        // console.log(
        //   this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab].ref
        //     .path
        // );
        if (!student["DATE_OF_BIRTH"]) {
          this.$swal.fire("Xаагаад буцаж орно уу!");
        } else {
          var studentData = {
            PERSON_ID: student["PERSON_ID"],
            FIRST_NAME: student["FIRST_NAME"],
            LAST_NAME: student["LAST_NAME"],
            GENDER_CODE: student["GENDER_CODE"],
            STUDENT_GROUP_ID: student["STUDENT_GROUP_ID"],
            STUDENT_GROUP_NAME: student["STUDENT_GROUP_NAME"],
            isDuplicated: student["isDuplicated"]
              ? student["isDuplicated"]
              : false,
            DATE_OF_BIRTH: student["DATE_OF_BIRTH"],
          };
          this.selectedCourse.eeljitLesson.lessonGroups[this.selectedTab].ref
            .collection("students")
            .doc(student.id)
            .set(studentData, { merge: true })
            .then(() => {
              var indexToDelete = this.selectedCourseStudents.findIndex(
                (t) => t.id == student.id
              );
              this.selectedCourseStudents.splice(indexToDelete, 1);
            });
        }
      } else {
        this.$swal.fire("Групп+ товч ашиглаад группэээxлээд үүсгэнэ үү.");
      }
    },
    _addLessonGroup() {
      if (
        this.selectedCourse.eeljitLesson &&
        this.selectedCourse.eeljitLesson.lessonGroups.length < 10
      ) {
        this.selectedCourse.eeljitLesson.ref
          .collection("lesson-groups")
          .doc()
          .set(
            {
              name:
                "Групп-" +
                (this.selectedCourse.eeljitLesson.lessonGroups.length + 1),
              groupIndex:
                this.selectedCourse.eeljitLesson.lessonGroups.length + 1,
            },
            { merge: true }
          )
          .then(() => {
            this.$forceUpdate();
          });
      } else {
        this.$swal.fire(
          "3 дээш групп үүсгэxгүй, xэрэв шаардлагатай бол админтай xолбоо барина уу!"
        );
      }
    },
    _showLessonGroupDialog(item) {
      this.selectedCourse = item;
      this.selectedCourseStudents = [];
      var tmpList = [];
      for (const lessonGroup of this.selectedCourse.eeljitLesson.lessonGroups) {
        for (const groupStudent of lessonGroup.students) {
          var xx = this.students.find((item) => item.id == groupStudent.id);
          if (xx) tmpList.push(groupStudent);
        }
      }
      for (const stud of this.students) {
        var found = tmpList.find((s) => s.id == stud.id);
        if (found == undefined) {
          this.selectedCourseStudents.push(stud);
        }
      }
      this.showLessonGroupDialog = !this.showLessonGroupDialog;
    },
    _readStudents() {
      this.school.ref
        .collection("students-" + this.school.currentYear)
        .orderBy("firstName", "asc")
        .where(
          "STUDENT_GROUP_ID",
          "==",
          this._getMyClassInfo()["STUDENT_GROUP_ID"]
        )
        .onSnapshot((querySnapshot) => {
          this.students = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            this.students.push(item);
          });
        });
    },
    _deleteItemConfirm() {
      for (var tt of this.selectedTeachersToDelete) {
        var indexToDelete =
          this.selectedCourse.eeljitLesson.byTeachers.indexOf(tt);
        if (indexToDelete > -1) {
          var batch = fb.db.batch();
          var teacherToDelete =
            this.selectedCourse.eeljitLesson.byTeachers[indexToDelete];

          this.selectedCourse.eeljitLesson.byTeachers.splice(indexToDelete, 1);

          // if (this.selectedCourse.eeljitLesson.byPreviousTeachers) {
          //   this.selectedCourse.eeljitLesson.byPreviousTeachers.push(
          //     teacherToDelete
          //   );
          // } else {
          //   this.selectedCourse.eeljitLesson.byPreviousTeachers = [];
          //   this.selectedCourse.eeljitLesson.byPreviousTeachers.push(
          //     teacherToDelete
          //   );
          // }

          batch.update(this.selectedCourse.eeljitLesson.ref, {
            byTeachers: this.selectedCourse.eeljitLesson.byTeachers,
            teacherRefs: this.selectedCourse.eeljitLesson.byTeachers.map(
              (item) => item.teacherRef
            ),
            // byPreviousTeachers:
            //   this.selectedCourse.eeljitLesson.byPreviousTeachers,
            // previousTeacherRefs:
            //   this.selectedCourse.eeljitLesson.byPreviousTeachers.map(
            //     (item) => item.teacherRef
            //   ),
          });

          for (const lgroup of this.selectedCourse.eeljitLesson.lessonGroups) {
            if (
              lgroup.teacher &&
              lgroup.teacher.teacherId == teacherToDelete.teacherRef.id
            ) {
              batch.update(lgroup.ref, { teacher: null });
            }
          }
          var tmp2 = {};
          tmp2["totalLessons-" + this.school.currentYear] =
            fb.firestore.FieldValue.increment(-1);
          batch.set(teacherToDelete.teacherRef, tmp2, { merge: true });
          batch.set(
            this.school.ref
              .collection("_statistics_teachers")
              .doc(teacherToDelete.teacherRef.id),
            tmp2,
            { merge: true }
          );
          batch.commit().then(() => {
            this.selectedCourse = null;
            this.selectedTeacher = null;
            this.selectedTeachersToDelete = [];
            this.showTeacherDeletionDialog = !this.showTeacherDeletionDialog;
          });
        }
      }
    },
    _createEeljitLesson(isAdditional) {
      console.log(
        this.selectedCourse,
        this.selectedTeacher,
        this.selectedEsisLessonType
      );
      if (
        this.selectedCourse &&
        this.selectedTeacher &&
        this.selectedEsisLessonType
      ) {
        var teacherInfo = {
          teacherRef: this.selectedTeacher.ref,
          teacherFirstName: this.selectedTeacher.firstName
            ? this.selectedTeacher.firstName
            : null,
          teacherLastName: this.selectedTeacher.lastName
            ? this.selectedTeacher.lastName
            : null,
          teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
            ? this.selectedTeacher.DISPLAY_NAME
            : null,
          teacherId: this.selectedTeacher.id,
          addedAt: new Date(),
          semester: this.currentSelectedSemester
            ? this.currentSelectedSemester
            : null,
        };
        this.selectedCourse.eeljitLesson = null;
        var lesson = {
          byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
          teacherRefs: fb.firestore.FieldValue.arrayUnion(
            this.selectedTeacher.ref
          ),
          year: this.school.currentYear,
          programId: this.selectedProgram.id,
          programRef: this.selectedProgram.ref,
          departmentId: this.selectedProgram.ref.parent.parent.id,
          courseInfo: this.selectedCourse,
          classGroupRefs: [this.selectedProgram.ref],
          classGroups: [
            {
              classGroupFullName: this.selectedProgram.name.toUpperCase(),
              classGroupName: this.selectedProgram.name.toUpperCase(),
              classGroupRef: this.selectedProgram.ref,
              departmentName: this.selectedProgram.ACADEMIC_LEVEL_NAME,
              departmentRef: this.selectedProgram.ref.parent.parent,
              programData: this.selectedProgram,
              ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL
                ? this.selectedProgram.ACADEMIC_LEVEL.toString()
                : this.selectedDepartment.department.index,
            },
          ],
          createdAt: new Date(),
          semester: this.currentSelectedSemester,
          deleted: false,
          description: null,
          esisLessonType: this.selectedEsisLessonType,
          isGroupBased:
            this.selectedCourse.ENROLLMENT_CATEGORY == "ELECTIVE"
              ? true
              : false,
          lessonType:
            this.selectedCourse.ENROLLMENT_CATEGORY == "MANDATORY"
              ? 1
              : this.selectedCourse.ENROLLMENT_CATEGORY == "ELECTIVE"
              ? 2
              : 0,
          lessonTypeName: this.selectedCourse.ENROLLMENT_CATEGORY_NAME,
        };
        if (isAdditional) {
          lesson.isAdditional = true;
        }
        this.loading = true;
        this.school.ref
          .collection("lessons-" + this.school.currentYear)
          .doc(this.selectedProgram.id + "-" + this.selectedCourse["COURSE_ID"])
          .set(lesson, { merge: true })
          .then(() => {
            // console.log(
            //   this.school.ref.path +
            //     "/" +
            //     "lessons-" +
            //     this.school.currentYear +
            //     "/" +
            //     this.selectedProgram.id +
            //     "-" +
            //     this.selectedCourse["COURSE_ID"]
            // );
            var tmp2 = {};
            tmp2["totalLessons-" + this.school.currentYear] =
              fb.firestore.FieldValue.increment(1);
            this.selectedTeacher.ref.update(tmp2).then(() => {
              this.loading = false;
              this.selectedEsisLessonType = null;

              if (isAdditional) {
                this.showAnotherPlan = !this.showAnotherPlan;
              } else {
                this.showTeacherSelectionDialog =
                  !this.showTeacherSelectionDialog;
              }
            });
            tmp2["DISPLAY_NAME"] = this.selectedTeacher["DISPLAY_NAME"];
            tmp2["teacherRef"] = this.selectedTeacher.ref;
            this.school.ref
              .collection("_statistics_teachers")
              .doc(this.selectedTeacher.id)
              .set(tmp2, { merge: true });
          });
      }
    },
    _getMyClassInfo(value) {
      if (this.zClassGroup) return this.zClassGroup[value];
      else return null;
    },
    _gotoLesson(categoryLesson) {
      this.$router.push({
        name: "PageLesson",
        params: {
          path: categoryLesson.lesson.ref.path,
        },
      });
    },
  },
};
</script>
<style>
.vselectRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}

.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
